import React from 'react';
import Plx from 'react-plx';

import './hero.scss'

import { scrollToId, EMAIL } from '../../utils'

import ArrowDown from '../../assets/images/arrow-down.inline';

const parallaxData = [
    {
      start: 0,
      end: 500,
      properties: [
        {
          startValue: 0,
          endValue: 200,
          property: 'translateY',
        },
      ],
    },
];

class Hero extends React.Component {
    showNext = () => scrollToId(document.getElementById('about'), 450, 'linear')
    render () {
        return (
            <section className="hero">
                <div className="container">
                    <Plx parallaxData={ parallaxData }>
                        <div className="hero__circle" data-aos="zoom-in"/>
                    </Plx>
                    <div className="row hero__row">
                        <div className="col-xs-12 col-md-6 hero__left">
                            <h1 className="g-h1" data-aos="">
                                <p><span>Black High-Tech Innovators and Entrepreneurs</span></p>
                                <p><span></span></p>
                            </h1>
                            <p className="g-p2" data-aos="fade-up" data-aos-duration=".6s" data-aos-delay="200"> Our mission is to promote and encourage high-technology innovation and entrepreneurship in black communities around the world.</p>
                            <div className="button-wrapper" data-aos="fade-up" data-aos-duration=".6s" data-aos-delay="400">
                                <a href={`mailto:ben@black-hightech.com`} className="g-button" title="Send us a email">Get in touch</a>
                            </div>
                            <div className="button-wrapper" data-aos="fade-up" data-aos-duration=".6s" data-aos-delay="600">
                                <button type="button" className="g-button g-button--outlined" onClick={this.showNext} arial-label="Learn more">Learn more</button>
                            </div>
                            <button data-aos="fade-up" type="button" className="hero__scroll" onClick={this.showNext} aria-label="Scroll to next section">
                                <ArrowDown />
                            </button>
                        </div>
                        <div className="hero__image"/>
                    </div>
                </div>
            </section>
        )
    }
}

export default Hero;
