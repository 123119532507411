import React from 'react';

import './about.scss';

const About = () => {
    return (
        <section className="about" id="about">
            <div className="container">
                <div className="hero__hands">
                    <div className="circle hero__hands-circle-1" />
                    <div className="circle hero__hands-circle-2" />
                    <p className="g-subtitle" data-aos="">The Problem</p>
                    <div className="hero__hands-row">
                        <p className="g-h3" data-aos="fade-up" data-aos-delay="300">Blacks and other minorities in the US have a very low representation in the entrepreneurship industry and even less in the high-tech entrepreneurship industry.</p>
                        <p className="g-p1" data-aos="fade-up" data-aos-delay="600">Black representation in the venture capitalist area is also very minimal. Consequently black entrepreneurs in many cases do not have access to the appropriate resources or networks to turn their innovations into the next billion dollar companies.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;