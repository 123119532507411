import React from 'react'

const TechnologiesNodeInline = (props) => {
  return React.createElement("svg", props, [React.createElement("defs", {"key": 0}, [React.createElement("linearGradient", {
    "x1": "50%",
    "y1": "100%",
    "x2": "50%",
    "y2": "0%",
    "id": "nodeb",
    "key": 0
  }, [React.createElement("stop", {
    "stopColor": "#5E67DF",
    "offset": "0%",
    "key": 0
  }), React.createElement("stop", {
    "stopColor": "#00EBAA",
    "offset": "100%",
    "key": 1
  })]), React.createElement("linearGradient", {
    "x1": "50%",
    "y1": "100%",
    "x2": "50%",
    "y2": "0%",
    "id": "noded",
    "key": 1
  }, [React.createElement("stop", {
    "stopColor": "#5E67DF",
    "offset": "0%",
    "key": 0
  }), React.createElement("stop", {
    "stopColor": "#00EBAA",
    "offset": "100%",
    "key": 1
  })]), React.createElement("path", {
    "id": "nodea",
    "d": "M0 .03h62.99v66.936H0z",
    "key": 2
  })]), React.createElement("g", {
    "fill": "none",
    "fillRule": "evenodd",
    "key": 1
  }, [React.createElement("g", {"key": 0}, [React.createElement("mask", {
    "id": "nodec",
    "fill": "#fff",
    "key": 0
  }, React.createElement("use", {"xlinkHref": "#nodea"})), React.createElement("path", {
    "d": "M28.653.745c1.721-.95 3.963-.954 5.683 0 8.651 4.714 17.305 9.42 25.955 14.137 1.627.884 2.716 2.63 2.7 4.438v28.36c.011 1.883-1.185 3.67-2.901 4.534-8.623 4.688-17.242 9.382-25.863 14.07-1.757.969-4.044.894-5.746-.154-2.585-1.445-5.175-2.883-7.76-4.327-.528-.303-1.124-.545-1.497-1.04.33-.428.92-.481 1.399-.668 1.079-.331 2.07-.862 3.061-1.378.25-.165.557-.102.797.046 2.21 1.223 4.402 2.48 6.62 3.69.473.264.952-.085 1.357-.303 8.462-4.612 16.934-9.206 25.394-13.819a.805.805 0 00.462-.797c.005-9.356.001-18.713.002-28.069.036-.376-.19-.721-.541-.875C49.18 13.924 40.593 9.25 32 4.582a.923.923 0 00-1.012-.001c-8.592 4.67-17.179 9.347-25.77 14.013-.352.154-.587.494-.545.871v28.07a.79.79 0 00.467.788c2.293 1.253 4.588 2.498 6.883 3.747 1.292.671 2.879 1.07 4.303.556 1.257-.435 2.137-1.671 2.113-2.958.012-9.3-.006-18.603.01-27.903-.032-.413.374-.754.79-.715 1.102-.007 2.205-.014 3.307.003.46-.01.776.434.72.85-.005 9.36.011 18.72-.008 28.08.003 2.495-1.06 5.21-3.453 6.43-2.948 1.472-6.591 1.16-9.504-.252-2.52-1.213-4.927-2.645-7.403-3.945-1.721-.86-2.91-2.654-2.899-4.535V19.32c-.018-1.845 1.114-3.623 2.791-4.494C11.413 10.134 20.033 5.439 28.653.745",
    "fill": "url(#nodeb)",
    "mask": "url(#nodec)",
    "key": 1
  })]), React.createElement("path", {
    "d": "M36.388 19.864c3.793-.236 7.855-.14 11.269 1.665 2.643 1.384 4.108 4.287 4.155 7.123-.074.383-.488.594-.866.568-1.101-.002-2.202.014-3.303-.008-.467.018-.738-.398-.797-.797-.316-1.357-1.082-2.7-2.404-3.355-2.03-.982-4.384-.933-6.598-.912-1.616.083-3.354.218-4.723 1.136-1.05.695-1.37 2.098-.995 3.229.354.811 1.324 1.073 2.118 1.315 4.571 1.155 9.416 1.04 13.9 2.56 1.857.62 3.674 1.824 4.31 3.702.83 2.516.466 5.524-1.388 7.545-1.503 1.662-3.692 2.567-5.876 3.058-2.905.626-5.92.642-8.87.364-2.774-.305-5.66-1.01-7.802-2.835-1.831-1.536-2.726-3.929-2.637-6.238.021-.39.423-.662.81-.63a169.93 169.93 0 013.325.001c.443-.03.771.34.794.742.205 1.293.708 2.65 1.875 3.417 2.253 1.404 5.08 1.307 7.659 1.347 2.137-.092 4.536-.12 6.28-1.484.92-.778 1.192-2.08.944-3.2-.27-.945-1.294-1.386-2.173-1.674-4.513-1.38-9.412-.879-13.882-2.438-1.814-.62-3.569-1.79-4.266-3.591-.973-2.549-.527-5.701 1.52-7.653 1.998-1.942 4.88-2.69 7.62-2.957z",
    "fill": "url(#noded)",
    "key": 1
  })])]);
}

TechnologiesNodeInline.defaultProps = {"width": "63", "height": "67", "viewBox": "0 0 63 67"};

export default TechnologiesNodeInline
