import React from "react"

import '../assets/stylesheets/shared.scss'
import Layout from "../components/layout"
import Seo from "../components/seo"

import Header from '../components/header'
import Hero from "../components/hero"
import About from "../components/about"
import OurVision from "../components/our-vision"
import Technologies from "../components/technologies"
import Hire from "../components/hire"
import GetInTouch from "../components/get-in-touch"
import Footer from "../components/footer"

const IndexPage = () => {
  return (
    <Layout>
      <Seo
        title="Black High-Tech Innovators and Entrepreneurs"
        description="Build trading bots wihtout needing to know how to code"
        keywords={[]}
        />
        <Header />
        <Hero />
        <About />
        <OurVision />
     
       
        <GetInTouch />
        <Footer/>
    </Layout>
  )
}


export default IndexPage
