import React from 'react'

const TechnologiesHtml5Inline = (props) => {
  return React.createElement("svg", props, [React.createElement("defs", {"key": 0}, React.createElement("linearGradient", {
    "x1": "50%",
    "y1": "100%",
    "x2": "50%",
    "y2": "0%",
    "id": "html5a"
  }, [React.createElement("stop", {
    "stopColor": "#5E67DF",
    "offset": "0%",
    "key": 0
  }), React.createElement("stop", {
    "stopColor": "#00EBAA",
    "offset": "100%",
    "key": 1
  })])), React.createElement("path", {
    "d": "M0 0l5.551 62.103L30.463 69l24.98-6.907L61 0H0zm11.276 15.411l-.185-2.056h38.818l-.185 2.056-.353 3.886-.156 1.723H19.56l.708 7.848H48.51l-.189 2.058L46.5 51.105l-.117 1.295-15.86 4.347v.001l-.036.01L14.613 52.4l-1.086-12.035h7.78l.551 6.113 8.631 2.305.008-.002.026-.008 8.617-2.3.9-9.94H13.18L11.276 15.41z",
    "fill": "url(#html5a)",
    "fillRule": "evenodd",
    "key": 1
  })]);
}

TechnologiesHtml5Inline.defaultProps = {"width": "61", "height": "69", "viewBox": "0 0 61 69"};

export default TechnologiesHtml5Inline
